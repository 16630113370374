.textContact {
    text-align: center;
}

.mwdesign {
    color: white;
    text-align: center;
    margin-right: auto;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    padding: 4px;

}

.certificationImg {
    display: inherit;
    margin: 30px auto;
    width: 110px;
}

@media only screen and (max-width: 900px) {
    .textContact {
        text-align: center;
    }

    .mwdesign {
        text-align: center;
        margin-right: auto;
    }
}

@media only screen and (min-width: 1080px) {
    .textContact {
        text-align: right;
    }

    .mwdesign {
        color: white;
        text-align: right;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        padding: 4px;
        margin-right: 15%;
    }

    .certificationImg {
        display: block;
        margin-left: auto;
        width: 110px;
    }
}