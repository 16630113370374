.greenBox1, .greenBox2 {
  text-align: center;
  color: #fff;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: #8cc54099;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  display: flex;
}

.greyBox {
  text-align: center;
  color: #fff;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: #02000099;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 11px;
  display: flex;
}

.topBoxContainer {
  max-width: 970px;
  margin-top: -104px;
  margin-left: auto;
  margin-right: auto;
}

.imagesCarousel {
  width: 100%;
  height: 300px;
}

@media only screen and (width >= 2160px) {
  .imagesCarousel {
    height: 1200px;
  }

  .greyBox, .greenBox1, .greenBox2 {
    height: 200px;
    font-size: 20px;
  }

  .topBoxContainer {
    margin-top: -213px;
  }
}

@media only screen and (width <= 1920px) {
  .imagesCarousel {
    height: 840px;
  }

  .greyBox, .greenBox1, .greenBox2 {
    height: 200px;
    font-size: 20px;
  }

  .topBoxContainer {
    margin-top: -213px;
  }
}

@media only screen and (width <= 1440px) {
  .imagesCarousel {
    height: 680px;
  }

  .greyBox, .greenBox1, .greenBox2 {
    height: 200px;
    font-size: 20px;
  }

  .topBoxContainer {
    margin-top: -213px;
  }
}

@media only screen and (width <= 768px) {
  .imagesCarousel {
    width: 100%;
    height: 640px;
  }

  .greenBox1, .greenBox2, .greyBox {
    height: 100px;
    font-size: 17px;
  }

  .topBoxContainer {
    margin-top: -113px;
  }
}

@media only screen and (width <= 550px) {
  .imagesCarousel {
    height: 300px;
  }

  .greenBox1, .greenBox2, .greyBox {
    height: 90px;
    font-size: 11px;
  }

  .topBoxContainer {
    margin-top: -104px;
  }
}

#app {
  height: 100vh;
}

body {
  max-width: 2600px;
  margin: 0 auto;
}

.topTextInService {
  text-align: center;
  color: #fff;
  padding: 5px;
}

.services {
  background-image: linear-gradient(#3f3f3fb3, #3f3f3f1a), url("https://images.unsplash.com/photo-1569428226604-5e24b547bcb4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 650px;
  margin-top: 50px;
  padding-bottom: 20px;
}

.servicesBoxes {
  text-align: center;
  color: #fff;
  background-color: #0200004d;
  border-radius: 30px 0;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 140px;
  margin: 3px;
  padding: 2px;
  display: flex;
}

@media only screen and (width >= 2160px) {
  .services {
    min-height: none;
    background-position: center;
  }

  .servicesBoxes {
    width: 500px;
    height: 210px;
    margin: 15px;
    padding: 5px;
  }
}

@media only screen and (width <= 1122px) {
  .servicesBoxes {
    width: 350px;
    height: 170px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (width <= 750px) {
  .services {
    background-position: 100%;
    min-height: 650px;
  }

  .servicesBoxes {
    width: auto;
    height: 140px;
    margin: 3px;
    padding: 2px;
  }
}

@media only screen and (width <= 450px) {
  .services {
    background-position: 100%;
    min-height: 650px;
    padding-bottom: 20px;
  }

  .servicesBoxes {
    width: auto;
    height: 120px;
    margin: 3px;
    padding: 2px;
  }
}

.textContact {
  text-align: center;
}

.mwdesign {
  color: #fff;
  text-align: center;
  margin-right: auto;
  padding: 4px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
}

.certificationImg {
  display: inherit;
  width: 110px;
  margin: 30px auto;
}

@media only screen and (width <= 900px) {
  .textContact {
    text-align: center;
  }

  .mwdesign {
    text-align: center;
    margin-right: auto;
  }
}

@media only screen and (width >= 1080px) {
  .textContact {
    text-align: right;
  }

  .mwdesign {
    color: #fff;
    text-align: right;
    margin-right: 15%;
    padding: 4px;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
  }

  .certificationImg {
    width: 110px;
    margin-left: auto;
    display: block;
  }
}

.logo {
  width: 170px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 100px;
}

.hello {
  border: 1px solid red;
}

@media only screen and (width >= 1080px) {
  .logo {
    width: 290px;
    margin-right: 100px;
  }
}

/*# sourceMappingURL=index.a51d3ca9.css.map */
