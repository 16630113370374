.logo {
    width: 170px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 100px;
}

.hello {
    border: red 1px solid;
}



@media only screen and (min-width: 1080px) {
    .logo {
        width: 290px;
        margin-right: 100;
    }
}